import { Model } from "./Model";
import { Either } from "prelude-ts";
import { DomainC } from "./tom/Domain";
import { User } from "./User";
import { GuestExpert } from "./GuestExpert";

export enum WrapperVisibility {
  Hidden = "Hidden",
  Visible = "Visible",
}

export enum WrapperStatus {
  InDevelopment = "InDevelopment",
  Restricted = "Restricted",
  Accessable = "Accessable",
}

export interface WrapperUserAccess {
  userId: string;
  canAccess: boolean;
  hasViewed: boolean;
  // Has the user hidden the wrapper from their dashboard.
  isHidden: boolean;
}

export interface ModelWrapper {
  _id: string;
  status: WrapperStatus;
  visibility: WrapperVisibility;
  isGuestViewable: boolean;
  associatedModel: Model;
  displayName: string;
  description: string;
  industry: string;
  outcome: string;
  inputWrappers: InputWrapper[];
  accessList: WrapperUserAccess[];
}

export interface GuestModelWrapper {
  _id: string;
  user: GuestExpert;
  status: WrapperStatus;
  visibility: WrapperVisibility;
  displayName: string;
  description: string;
  sector: string;
}

export enum InputWrapperType {
  Selectable = "Selectable Option",
  Numeric = "Numeric Value",
}

export interface InputWrapper {
  inputType: InputWrapperType;
  decisionInput: string;
  frontendQuestion?: string;
  frontendHint?: string;
}

export interface OptionWrapper {
  displayName?: string;
  description?: string;
}

export interface SelectableInputWrapper extends InputWrapper {
  options: OptionWrapper[];
}

export interface NumericInputWrapper extends InputWrapper {
  minimumValue: number;
  maximumValue: number;
}

export function wrapperValueToModelValue(
  modelWrapper: ModelWrapper,
  wrapperQuestion: string,
  wrapperValue: any
): Either<Error, string | number> {
  const attributes = modelWrapper.associatedModel.data.metadata.attributes;
  const inputWrapper = modelWrapper.inputWrappers.find(
    x => x.frontendQuestion === wrapperQuestion
  );

  if (!inputWrapper) {
    return Either.left(new Error(`Question '${wrapperQuestion}' not found in wrapper`));
  }

  const attribute = attributes.find(x => [x.question, x.name].includes(inputWrapper.decisionInput));
  if (!attribute) {
    return Either.left(new Error(`Question '${wrapperQuestion}' not linked to an attribute`));
  }

  if (!(typeof wrapperValue === 'number' || typeof wrapperValue === 'string')) {
    return Either.left(new Error(`Question <'${attribute.name}' - '${wrapperQuestion}'> has incorrect data type.`));
  }

  if (inputWrapper.inputType === InputWrapperType.Numeric) {
    const niw = inputWrapper as NumericInputWrapper;
    const num = +wrapperValue;

    if (isNaN(num) || num < niw.minimumValue || num >= niw.maximumValue)
      return Either.left(new Error(
        `Out of range: question <'${attribute.name}' - '${wrapperQuestion}'> should be a number in the range (${niw.minimumValue}, ${niw.maximumValue}]`
      ));

    return Either.right(num);
  }

  if (inputWrapper.inputType === InputWrapperType.Selectable) {
    const siw = inputWrapper as SelectableInputWrapper;
    const wrapperOptionIndex = siw.options.findIndex(x => x.displayName === wrapperValue);

    let values: string[];
    try {
      values = (attribute.domain as DomainC).values;
    } catch (e) {
      return Either.left(new Error(`Incorrect InputWrapperType for question <'${attribute.name}' - '${wrapperQuestion}'>`));
    }

    if (wrapperOptionIndex < 0 || wrapperOptionIndex >= values.length) {
      return Either.left(new Error(`Invalid mapping: value '${wrapperValue}' for question <'${attribute.name}' - '${wrapperQuestion}'> not linked to a valid attribute. Possible values are: ['${siw.options.map(x => x.displayName).join("', '")}']`));
    }

    return Either.right(values[wrapperOptionIndex]);
  }

  return Either.left(new Error(`Invalid input type for question <'${attribute.name}' - '${wrapperQuestion}'>`));
}

export function hasModelAccess(user: User, modelWrapper: ModelWrapper) {
  return modelWrapper.accessList.find((access) => access.userId == user._id && access.canAccess) !== undefined;
}
