import { Dispatch, FunctionComponent, useState } from "react";

import { TristateButton } from "components/elements/TristateButton";
import UserAvatar from "@/components/elements/UserAvatar";

import { GuestExpert } from "@/lib/interfaces/GuestExpert";
import {
  GuestModelWrapper,
  WrapperStatus,
  WrapperVisibility
} from "@/lib/interfaces/ModelWrapper";

//#region INTERFACES
interface ModelsListProps {
  experts: GuestExpert[];
  modelWrappers: GuestModelWrapper[];
  isExploring: boolean;
  onModelSelect: Dispatch<GuestModelWrapper>;
}
//#endregion

//#region CONSTANTS
// Always show 'View' on the guest platform.
const ModelStatusButtonNames = ["View", "View", "View"];
//#endregion

//#region PUBLIC API
export const ModelsList: FunctionComponent<ModelsListProps> = ({
  experts,
  modelWrappers,
  isExploring,
  onModelSelect,
}) => {

  // When focused index is -1, no items have been focused.
  const [focusedIndex, setFocusedIndex] = useState(-1);

  return (
    <>
      <div className="flex flex-col px-8">
        <div className="-my-2 -mx-8">
          <div className="py-2 align-middle inline-block w-full">
            <div className="overflow-x-hidden border-b bg-white border-gray-200 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 scrollbar-track-rounded scrollbar-thumb-rounded">
              <table className="w-full divide-y divide-gray-400">
                <thead className="hover:cursor-default shadow-lg">
                  <tr className="text-xxs bg-white bg-opacity-50 text-left">
                    <th
                      scope="col"
                      className="pl-8 py-3 font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Digital Twin
                    </th>
                    {isExploring &&
                      <th
                        scope="col"
                        className="pl-8 py-3 font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {/* Access */}
                      </th>
                    }
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody className="divide-gray-400 hover:cursor-default">
                  {modelWrappers
                    .filter((_) => _.visibility === WrapperVisibility.Visible)
                    .map((wrapper, index) => (
                      <>
                        <tr
                          className={`group bg-denim-700 bg-opacity-20 ${focusedIndex === index
                            ? "bg-denim-500 bg-opacity-50 h-24"
                            : "hover:bg-denim-600 hover:bg-opacity-10 h-18"
                            } transition-all ease-out duration-300`}
                          key={`${wrapper._id}${index}`}
                        >
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center justify-start">
                              <div className={`flex-shrink-0 h-8 w-8 ${index === focusedIndex ? "animate-pulse" : ""}`}>
                                <UserAvatar
                                  size={"h-8 w-8"}
                                  background={"bg-turqoise-blue"}
                                />
                              </div>
                              <div className="ml-2">
                                <div className="flex">
                                  <div className={`font-aiea text-sm w-64 truncate ${index === focusedIndex ? "font-semibold" : "font-medium"} text-dark-background`}>
                                    {wrapper.displayName}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="pl-12 py-4 whitespace-nowrap items-center">
                            {isExploring && wrapper.status === WrapperStatus.InDevelopment &&
                              <span
                                className={`px-3 py-1 text-xs leading-5 font-semibold rounded-full bg-denim-900 text-white shadow-md`}
                              >
                                {
                                  wrapper.status
                                }
                              </span>
                            }
                          </td>
                          <td
                            onClick={() => {
                              setFocusedIndex(index);
                              onModelSelect(wrapper);
                            }}
                            className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                          >
                            {isExploring ? (
                              <div
                                className={`z-100 rounded-lg text-center font-aiea h-8 bg-turqoise-blue text-denim-900 hover:cursor-pointer group-hover:bg-denim-900 group-hover:text-white transition ease-out duration-300 shadow-lg`}
                              >
                                <p className="py-1.5 px-2 mx-1.5">View</p>
                              </div>
                            ) : (
                              <TristateButton
                                state={
                                  wrapper.status === WrapperStatus.InDevelopment ? 0 : 2
                                }
                                stateDisplayNames={ModelStatusButtonNames}
                              />
                            )}
                          </td>
                        </tr>
                        <div className="relative w-full ml-6 mr-8 mb-4 text-dark-background">
                          <p className="text-xs font-semibold font-aiea pt-4">
                            {wrapper.displayName}
                          </p>
                          <p
                            className="text-xs font-aiea pt-4"
                            style={{ whiteSpace: "pre-wrap" }}>
                            {wrapper.description === undefined || wrapper.description.length === 0 ? "" : wrapper.description}
                          </p>
                        </div>
                      </>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
//#endregion
