import { FC } from "react";

//#region PUBLIC API
export const MITBadge: FC =
  () => {
    return (
      <div className="absolute bottom-10 left-10 font-aiea text-opacity-50 text-white origin-bottom-left transform scale-aiea">
        <div className="flex-col items-center">
          <div className="flex items-center">
            <img
              src={"/images/logos/mit-logo.png"}
              className="h-16"
            />
          </div>
        </div>
      </div>
    );
  };
//#endregion