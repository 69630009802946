import { FunctionComponent } from "react";

import { Transition } from "@headlessui/react";

//#region INTERFACES
interface WindowTransitionProps {
  isShowing: boolean;
  isOutDisabled: boolean;
}
//#endregion

//#region PUBLIC API
export const WindowTransition: FunctionComponent<WindowTransitionProps> = ({
  isShowing,
  isOutDisabled,
  children,
}) => {
  return (
    <>
      <Transition
        show={isShowing}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave={`${isOutDisabled ? "" : "transition ease-in duration-75"}`}
        leaveFrom={`${isOutDisabled ? "" : "transform opacity-100 scale-100"}`}
        leaveTo={`${isOutDisabled ? "" : "transform opacity-0 scale-95"}`}
      >
        {children}
      </Transition>
    </>
  );
};
//#endregion
