import { Dispatch, FunctionComponent } from "react";

import { ModelsList } from "components/elements/ModelsList";
import { WindowTransition } from "components/transitions/WindowTransition";
import UserAvatar from "@/components/elements/UserAvatar";

import { GuestExpert } from "@/lib/interfaces/GuestExpert";
import { GuestModelWrapper } from "@/lib/interfaces/ModelWrapper";

//#region INTERFACES
interface ExpertSelectionBarProps {
  sector: string;
  experts: GuestExpert[];
  modelWrappers: GuestModelWrapper[];
  selectedSector?: string;
  onSectorSelected: Dispatch<string | undefined>;
  onModelSelect: Dispatch<GuestModelWrapper>;
}
//#endregion

//#region PUBLIC API
export const SectorSelectionBar: FunctionComponent<ExpertSelectionBarProps> = ({
  sector,
  experts,
  modelWrappers,
  selectedSector,
  onSectorSelected,
  onModelSelect,
}) => {
  function GetExpertHasSkills(expert: GuestExpert) {
    return !!expert.profile.skills.length;
  }

  return (
    <>
      <div
        className={`grid grid-cols-1 divide-y divide-gray-400 space-y-2 hover:bg-denim-700 hover:bg-opacity-20 ${selectedSector === sector ? "bg-denim-600 bg-opacity-10" : ""
          } transition duration-300 ease-out`}
      >
        <h3
          onClick={() =>
            onSectorSelected(sector === selectedSector ? undefined : sector)
          }
          className="mt-4 mx-8 font-aiea font-semibold text-dark-background hover:cursor-pointer"
        >
          {sector}
        </h3>
        <div
          onClick={() =>
            onSectorSelected(sector === selectedSector ? undefined : sector)
          }
          className="flex mx-8 space-x-4 justify-start overflow-x-auto hover:cursor-pointer items-center flex-nowrap scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 scrollbar-track-rounded scrollbar-thumb-rounded"
        >
          {experts.slice(0, 7).map((expert) => (
            <div
              key={`${sector}-${expert._id}`}
              className="flex flex-shrink-0 mt-3 mb-4 space-x-2 items-center flex-nowrap"
            >
              <UserAvatar
                size={"h-14 w-14"}
                background={"bg-turqoise-blue"}
              />
              <div className="flex-col space-y-1 font-aiea text-sm flex-nowrap whitespace-nowrap">
                {GetExpertHasSkills(expert) && (
                  <>
                    {expert.profile.skills[0].length > 0 && (
                      <p>#{expert.profile.skills[0]}</p>
                    )}
                    {expert.profile.skills.length >= 2 &&
                      expert.profile.skills[1].length > 0 && (
                        <p>#{expert.profile.skills[1]}</p>
                      )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
        <WindowTransition
          isShowing={!!selectedSector && selectedSector === sector}
          isOutDisabled={false}
        >
          <div>
            <ModelsList
              experts={experts}
              modelWrappers={modelWrappers}
              onModelSelect={onModelSelect}
              isExploring={true}
            />
          </div>
        </WindowTransition>
      </div>
    </>
  );
};
//#endregion
