import { Dispatch, FunctionComponent, useEffect, useState } from "react";

import { GuestExpert } from "@/lib/interfaces/GuestExpert";

//#region INTERFACES
interface ExpertOverviewProps {
  expert: GuestExpert;
  onViewModelClicked: Dispatch<void>;
}
//#endregion


//#region PUBLIC API
export const ExpertOverview: FunctionComponent<ExpertOverviewProps> = ({
  expert,
  onViewModelClicked,
}) => {
  const [hasViewedModel, setHasViewedModel] = useState(false);

  useEffect(() => {
    if (!hasViewedModel) {
      onViewModelClicked();
      setHasViewedModel(true);
    }
  });

  return (
    <>
      <div className="font-aiea h-full text-dark-background mt-4 grid grid-cols-1 divide-y divide-gray-400">
        <div>
          <h2 className="font-bold text-2xl">About</h2>
          <p className="mt-2">{expert.profile.location}</p>
          {expert.profile.position !== "None" && <p>{expert.profile.position}</p>}
        </div>

        {expert.profile.notes && (expert.profile.notes as string).length > 0 && (
          <div className="mt-4 py-4 h-full">
            <p
              className="text-sm"
              style={{ whiteSpace: "pre-wrap" }}>
              {expert.profile.notes}
            </p>
          </div>
        )}
        <div>
        </div>
      </div>
    </>
  );
};
//#endregion
