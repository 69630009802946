import { Dispatch, FunctionComponent, useState } from "react";

import { WindowTransition } from "components/transitions/WindowTransition";
import { CloseButton } from "components/elements/NavigationButtons";
import { EmailSentView } from "components/elements/EmailSentView";
import { MultilineInputField } from "@/components/elements/MultilineInputField";
import UserAvatar from "@/components/elements/UserAvatar";

import {
  GuestModelWrapper
} from "@/lib/interfaces/ModelWrapper";
import { Panel } from "./Panel";
import { Modal } from "@/ui/Modal";

//#region INTERFACES
interface ModelStatusWindowProps {
  modelWrapper: GuestModelWrapper;
  onClosed: Dispatch<boolean>;
}
//#endregion

function ValidateEmail(email: string): boolean {
  // HACK TODO We should match using regex - let's do this when building out a validation/input utilities class.
  return email.includes("@") && email.includes(".");
}

//#region PUBLIC API
export const ViewModelWindow: FunctionComponent<ModelStatusWindowProps> = ({
  modelWrapper,
  onClosed,
}) => {

  interface EmailProps {
    requestedDTName: string;
    requesterName: string | undefined;
    requesterEmail: string | undefined;
    requesterMessage: string | undefined;
  }

  const [showConnectModal, setShowConnectModal] = useState(false);
  const [areInputsValid, setAreInputsValid] = useState(false);

  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [wasSendingSuccessful, setWasSendingSuccessful] = useState(false);
  const [emailProps, setEmailProps] = useState<EmailProps>({
    requestedDTName: modelWrapper?.displayName,
    requesterEmail: undefined,
    requesterName: undefined,
    requesterMessage: undefined
  });

  function ValidateInputs() {
    setAreInputsValid(
      emailProps.requesterEmail !== undefined &&
      ValidateEmail(emailProps.requesterEmail) &&
      emailProps.requesterName !== undefined &&
      emailProps.requesterMessage !== undefined
    );
  }

  async function SendEmail() {
    setIsEmailSending(true);

    const response = await fetch("/api/request-platform-access", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(emailProps),
    });

    if (response) {
      setIsEmailSent(true);
      setWasSendingSuccessful(response.ok);
      setIsEmailSending(false);
    }
  }

  return (
    <>
      <WindowTransition isShowing={true} isOutDisabled={false}>
        <Panel>
          <div
            onClick={() => onClosed(true)}
            className="mr-3 flex justify-end mt-3"
          >
            <CloseButton />
          </div>
          <div className="flex items-center -mt-6 my-4 mx-4">
            <UserAvatar
              size={"h-16 w-16"}
              background={"bg-turqoise-blue"}
            />
            <div className="font-aiea mx-6">
              <h2 className="font-bold text-lg">{modelWrapper.displayName}</h2>
            </div>
          </div>
          <h2
            className="mx-6 my-2 font-light text-lg overflow-y-auto max-h-120 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 scrollbar-track-rounded scrollbar-thumb-rounded"
            style={{ whiteSpace: "pre-wrap" }}
          >
            {modelWrapper.description === undefined ||
              modelWrapper.description.length === 0
              ? ""
              : modelWrapper.description}
          </h2>
          <div className="flex ml-6 mt-4 space-x-2">
            <a
              onClick={() => {
                setIsEmailSent(false);
                setWasSendingSuccessful(false);
                setShowConnectModal(true);
              }}
              target="_blank"
              className={`z-50 shadow-lg mb-6 rounded-lg text-center font-aiea h-8 w-28 hover:cursor-pointer hover:bg-gray-100 hover:text-denim-900 hover:border-turqoise-blue bg-denim-900 text-white border-solid border-2 border-denim-900 transition ease-out duration-300`}
            >
              <p className="py-1 mx-1.5">Enquire</p>
            </a>
          </div>
        </Panel>
        <Modal open={showConnectModal} onClose={() => {
          setShowConnectModal(false);
          setEmailProps({
            requestedDTName: modelWrapper?.displayName,
            requesterEmail: undefined,
            requesterName: undefined,
            requesterMessage: undefined
          });
          setAreInputsValid(false);
        }}>
          <div className="mx-8 my-8">
            <p className=" font-semibold mt-4 text-2xl text-center text-dark-background">
              {modelWrapper.displayName}
            </p>
            <p className=" font-light mt-2 text-lg text-center text-dark-background">
              {modelWrapper.user.profile.position} Expert
            </p>
            <div className="bg-black h-0.5 opacity-30 my-6"></div>
            {!isEmailSent ? (
              <>
                <p className="mx-4 mb-8 text-center font-light text-xl text-dark-background">
                  Please complete the form below to enquire about this Digital Twin
                </p>
                <div className="w-full mb-4">
                  <input
                    type="name"
                    placeholder="Full Name"
                    onChange={(event) => {
                      setEmailProps({
                        requestedDTName: emailProps.requestedDTName,
                        requesterName: event.target.value,
                        requesterEmail: emailProps.requesterEmail,
                        requesterMessage: emailProps.requesterMessage
                      });
                      ValidateInputs();
                    }}
                    className="p-2 px-6 text-center font-aiea block w-full h-full shadow-sm text-warm-gray-900 focus:ring-denim-900 focus:border-denim-900 border-0 bg-turqoise-blue bg-opacity-40 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 scrollbar-track-rounded scrollbar-thumb-rounded">
                  </input>
                  <input
                    type="email"
                    placeholder="Email"
                    onChange={(event) => {
                      setEmailProps({
                        requestedDTName: emailProps.requestedDTName,
                        requesterName: emailProps.requesterName,
                        requesterEmail: event.target.value,
                        requesterMessage: emailProps.requesterMessage
                      });
                      ValidateInputs();
                    }}
                    className="p-2 px-6 mt-2 mb-6 text-center font-aiea block w-full h-full shadow-sm text-warm-gray-900 focus:ring-denim-900 focus:border-denim-900 border-0 bg-turqoise-blue bg-opacity-40 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 scrollbar-track-rounded scrollbar-thumb-rounded">
                  </input>
                  <p className="mb-2 text-center font-light text-xl text-dark-background">
                    What is your reason for enquiry?
                  </p>
                  <div className="h-36">
                    <MultilineInputField
                      valueText={
                        emailProps.requesterMessage === undefined
                          ? ""
                          : emailProps.requesterMessage
                      }
                      id="contact-request-message"
                      placeholderText="Type message here"
                      isTextCentered={true}
                      onValueChanged={(message) => {
                        setEmailProps({
                          requestedDTName: emailProps.requestedDTName,
                          requesterName: emailProps.requesterName,
                          requesterEmail: emailProps.requesterEmail,
                          requesterMessage: message
                        });
                        ValidateInputs();
                      }}
                    />
                  </div>
                  <div className="flex justify-center mt-8">
                    <div
                      onClick={() => {
                        if (areInputsValid && !isEmailSending) {
                          SendEmail();
                        }
                      }}
                      className={`z-50 bg-turqoise-blue rounded-lg text-center font-aiea h-10 ${areInputsValid
                        ? "hover:cursor-pointer hover:bg-gray-100 hover:text-denim-900 hover:border-solid hover:border-2 hover:border-turqoise-blue"
                        : "text-gray-500 bg-opacity-40 "
                        } transition ease-out duration-300 ${isEmailSending ? "animate-pulse" : ""
                        }`}
                    >
                      <p className="py-2 px-2 mx-3 font-semibold">
                        {isEmailSending ? "Enquiring..." : "Enquire"}
                      </p>
                    </div>
                  </div>
                </div>
              </>) : (
              <>
                <div className="mt-6 mb-10">
                  <EmailSentView
                    isVisible={isEmailSent}
                    wasSuccessful={wasSendingSuccessful}
                    notificationMessage={
                      "Your request to connect has been sent successfully"
                    }
                  />
                </div>
                {isEmailSent &&
                  <div className="flex mx-68 -mb-4">
                    <a
                      onClick={() => {
                        setShowConnectModal(false);
                        setEmailProps({
                          requestedDTName: modelWrapper?.displayName,
                          requesterEmail: undefined,
                          requesterName: undefined,
                          requesterMessage: undefined
                        });
                        setAreInputsValid(false);
                      }}
                      className={`z-50 shadow-lg mb-6 rounded-lg text-center font-aiea h-8 w-32 hover:cursor-pointer hover:bg-gray-100 hover:text-denim-900 hover:border-turqoise-blue bg-denim-900 text-white border-solid border-2 border-denim-900 transition ease-out duration-300`}
                    >
                      <p className="py-1 mx-1.5">Close</p>
                    </a>
                  </div>
                }
              </>
            )
            }
          </div>
        </Modal>

      </WindowTransition>
    </>
  );
};
//#endregion
