//#region INTERFACES
interface GuestWorldAvatarProps {
  size: string;
  index: number;
}
//#endregion

export default function GuestWorldAvatar({
  size,
  index
}: GuestWorldAvatarProps) {

  return (
    <>
      <div
        className={`flex ${size} rounded-full items-center overflow-hidden`}
      >
        <img
          className="object-cover shadow-md mx-auto my-auto"
          src={`images/fakeAvatars/avatar_${index}.jpg`}
        />
      </div>
    </>
  );
}
