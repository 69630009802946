import { Dispatch, FunctionComponent } from "react";

import UserAvatar from "@/components/elements/UserAvatar";

import { GuestExpert } from "@/lib/interfaces/GuestExpert";

//#region INTERFACES
interface ModelsListProps {
  experts: GuestExpert[];
  onExpertSelect: Dispatch<GuestExpert>;
}
//#endregion

//#region PUBLIC API
export const ExpertsList: FunctionComponent<ModelsListProps> = ({
  experts,
  onExpertSelect,
}) => {
  return (
    <>
      <div className="flex flex-col">
        <div className="-my-2 -mx-8">
          <div className="py-2 align-middle inline-block w-full px-8">
            <div className={`overflow-hidden border-b border-gray-200 ${experts?.length > 0 ? "scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 scrollbar-track-rounded scrollbar-thumb-rounded" : ""}`}>
              {experts?.length > 0 ? (
                <>
                  <table className="w-full divide-y divide-gray-200">
                    <thead className="bg-white bg-opacity-50 shadow-lg hover:cursor-default">
                      <tr className="text-xxs">
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Expert
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Location
                        </th>
                        <th scope="col" className="px-6 py-3"></th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-400 hover:cursor-default">
                      {experts.map((expert) => (
                        <tr
                          className="group hover:bg-denim-700 hover:bg-opacity-20 transition-all ease-out duration-300"
                          key={expert._id}
                        >
                          <td className="px-4 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 h-8 w-8">
                                <UserAvatar
                                  size={"h-8 w-8"}
                                  background={"bg-turqoise-blue"}
                                />
                              </div>
                              <div className="ml-2">
                                <div className="text-xs w-64 truncate font-medium text-dark-background">
                                  {expert.profile.position} Expert
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-xs w-32 truncate text-gray-500">
                              {expert.profile.location === undefined || expert.profile.location?.length === 0
                                ? "Global"
                                : expert.profile.location}
                            </div>
                          </td>
                          <td
                            onClick={() => {
                              onExpertSelect(expert);
                            }}
                            className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                          >
                            <div
                              className={`z-100 rounded-lg text-center font-aiea h-8 bg-turqoise-blue text-denim-900 hover:cursor-pointer group-hover:bg-denim-900 group-hover:text-white transition ease-out duration-300 shadow-md`}
                            >
                              <p className="py-1.5 mx-1.5">View</p>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <p className="mb-6 ml-8 font-aiea text-lg font-semibold">
                  There are no visible experts in this industry.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
//#endregion
