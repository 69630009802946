import { FunctionComponent } from "react";
import { ExclamationIcon } from "@heroicons/react/outline";

import { WindowTransition } from "components/transitions/WindowTransition";

//#region INTERFACES
interface EmailSentViewProps {
  isVisible: boolean;
  wasSuccessful: boolean;
  notificationMessage: string;
}
//#endregion

//#region PUBLIC API
export const EmailSentView: FunctionComponent<EmailSentViewProps> = (props) => {
  const { isVisible, wasSuccessful, notificationMessage } = props;

  return (
    <>
      <WindowTransition isShowing={isVisible} isOutDisabled={true}>
        {wasSuccessful ? (
          <>
            <div className="flex align-middle ">
              {/* <MailIcon className="h-28 w-28 text-dark-background mx-auto my-auto" /> */}
              <img
                src={"/images/email-sent.png"}
                className="h-38 mx-auto my-auto"
              />
            </div>
            <h2 className="mt-6 font-aiea text-center text-lg font-semibold">
              Email Sent
            </h2>
            <h2 className="font-aiea text-center px-8 pt-2 text-sm">
              {notificationMessage}
            </h2>
          </>
        ) : (
          <>
            <div className="flex align-middle">
              <ExclamationIcon className="h-36 w-36 text-red-800 mx-auto my-auto" />
            </div>
            <h2 className="font-aiea mt-4 text-center text-lg font-semibold text-red-900">
              Email Failed
            </h2>
            <h2 className="font-aiea text-center px-8 pt-2 text-sm">
              Something went wrong while sending your email
            </h2>
          </>
        )}
      </WindowTransition>
    </>
  );
};
//#endregion
