import { useState } from "react";
import { useMutation } from "react-query";

import { MultilineInputField } from "components/elements/MultilineInputField";
import { EmailSentView } from "components/elements/EmailSentView";
import { CloseButton } from "components/elements/NavigationButtons";
import { useAppDispatch } from "@/app/hooks";
import { hideChatWindow } from "features/chat/chatSlice";
import { Api } from "@/app/api";

interface EmailProps {
  fullName: string | undefined;
  email: string | undefined;
  message: string | undefined;
}

//#region PUBLIC API
export const ChatWindow = ({ }) => {
  const dispatch = useAppDispatch();

  const sendEmail = useMutation(
    ({
      fullName,
      email,
      message,
    }: {
      fullName: string;
      email: string;
      message: string;
    }) => Api.sendContactEmail(fullName, email, message)
  );

  const [areInputsValid, setAreInputsValid] = useState(false);
  const [emailProps, setEmailProps] = useState<EmailProps>(
    {
      fullName: undefined,
      email: undefined,
      message: undefined
    }
  );


  function ValidateInputs() {
    setAreInputsValid(
      emailProps.fullName !== undefined &&
      ValidateEmail(emailProps.email) &&
      emailProps.message !== undefined
    );
  }

  function ValidateEmail(email: string | undefined): boolean {
    // HACK TODO We should match using regex - let's do this when building out a validation/input utilities class.
    return email !== undefined && email.includes("@") && email.includes(".");
  }

  return (
    <>
      <div className="bg-white w-112 bg-opacity-95 rounded-2xl border-solid border-4 border-gray-300">
        <div className="grid grid-cols-1 divide-y divide-gray-400 space-y-2 mx-4">
          <div className="flex space-x-12 justify-between w-full overflow-x-auto pt-4">
            <h3 className="font-aiea font-semibold text-dark-background">
              Get in touch with us
            </h3>
            <div onClick={() => dispatch(hideChatWindow())} className="-mt-1">
              <CloseButton />
            </div>
          </div>
          <div className="flex space-x-12 justify-between w-full overflow-x-auto"></div>
        </div>
        <div className="mx-4 font-aiea">
          {(sendEmail.isIdle || sendEmail.isLoading) && (
            <>
              <p className="my-4 text-sm text-center text-gray-500">
                We have a team of experts who are here to help.
              </p>
              <div className="mx-4">
                <input
                  type="name"
                  placeholder="Full Name"
                  onChange={(event) => {
                    setEmailProps({
                      fullName: event.target.value,
                      email: emailProps.email,
                      message: emailProps.message
                    });
                    ValidateInputs();
                  }}
                  className="p-2 px-3 text-left font-aiea block w-full h-full shadow-sm text-warm-gray-900 focus:ring-denim-900 focus:border-denim-900 border-0 bg-turqoise-blue bg-opacity-40 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 scrollbar-track-rounded scrollbar-thumb-rounded">
                </input>
                <input
                  type="email"
                  placeholder="Email"
                  onChange={(event) => {
                    setEmailProps({
                      fullName: emailProps.fullName,
                      email: event.target.value,
                      message: emailProps.message
                    });
                    ValidateInputs();
                  }}
                  className="p-2 px-3 mt-2 mb-3 text-left font-aiea block w-full h-full shadow-sm text-warm-gray-900 focus:ring-denim-900 focus:border-denim-900 border-0 bg-turqoise-blue bg-opacity-40 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 scrollbar-track-rounded scrollbar-thumb-rounded">
                </input>
              </div>
              <div className="bg-black h-0.5 opacity-30 mx-4 shadow-lg"></div>
              <div className="flex justify-center mx-4 h-32">
                <MultilineInputField
                  id="chat-message"
                  valueText={emailProps.message as string}
                  placeholderText="Type message here"
                  onValueChanged={(value) => {
                    setEmailProps({
                      fullName: emailProps.fullName,
                      email: emailProps.email,
                      message: value
                    });
                    ValidateInputs();
                  }}
                />
              </div>
              <div className="bg-black h-0.5 opacity-30 mx-4 shadow-lg"></div>
              <div className="flex justify-left mt-4 mx-4">
                <div
                  onClick={() => {
                    if (areInputsValid && sendEmail.isIdle) {
                      sendEmail.mutate({ fullName: emailProps.fullName as string, email: emailProps.email as string, message: emailProps.message as string });
                    }
                  }}
                  className={`z-50 bg-turqoise-blue rounded-lg text-center font-aiea h-8 ${areInputsValid
                    ? "hover:cursor-pointer hover:bg-gray-100 hover:text-denim-900 border-solid border-2 border-turqoise-blue border-opacity-0 hover:border-opacity-100"
                    : "text-gray-500 bg-opacity-40 "
                    } transition ease-out duration-300 ${sendEmail.isLoading ? "animate-pulse" : ""
                    }`}
                >
                  <p className="py-1 px-4">
                    {sendEmail.isLoading ? "Sending..." : "Send"}
                  </p>
                </div>
              </div>
            </>
          )}
          <div className="flex w-full h-full mb-8">
            <EmailSentView
              isVisible={sendEmail.isSuccess || sendEmail.isError}
              wasSuccessful={sendEmail.isSuccess}
              notificationMessage={
                "Thank you for getting in touch with us. We will respond shortly."
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
//#endregion
