import { Dispatch, useState, FC, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";

import { GuestExpertWithWrappers } from "@/lib/interfaces/GuestExpert";
import { WrapperVisibility } from "@/lib/interfaces/ModelWrapper";

import { Spinner } from "@/ui/Spinner";
import { Api } from "@/app/api";

//#region INTERFACES
interface SectorWindowProps {
  guestExpertWrappers: GuestExpertWithWrappers[];
  onSampleSectorSelected: Dispatch<string | undefined>;
  onRestrictedSectorSelected: Dispatch<undefined>;
}
//#endregion

//#region PUBLIC API
export const SectorWindow: FC<SectorWindowProps> = ({
  guestExpertWrappers,
  onSampleSectorSelected,
  onRestrictedSectorSelected
}) => {

  const queryClient = useQueryClient();

  //#region VARIABLES - STATE
  const sectors = useQuery("sectors", Api.getSectors);
  const [currentSector, setCurrentSector] = useState<string>();

  const SAMPLE_SECTOR_NAME: string = "Sample Content";

  const [initialized, setInitialized] = useState(false);
  //#endregion


  useEffect(() => {
    if (!initialized) {
      queryClient.invalidateQueries("sectors");
      setInitialized(true);
    }
  });

  function getExpertsCount(sector: string): number {
    let globalSectorExperts: GuestExpertWithWrappers[] = [];

    for (let i = 0; i < guestExpertWrappers.length; ++i) {
      if (!globalSectorExperts.includes(guestExpertWrappers[i])) {
        let expert: GuestExpertWithWrappers = guestExpertWrappers[i];

        for (let j = 0; j < expert.wrappers.length; ++j) {
          if (expert.wrappers[j].visibility === WrapperVisibility.Visible && expert.wrappers[j].sector === sector) {
            globalSectorExperts.push(expert);
            break;
          }
        }
      }
    }
    return globalSectorExperts.length;
  }


  //#region HELPER FUNCTIONS
  const isCurrentSector = (sector: string) => sector === currentSector;
  //#endregion

  return (
    <div
      className={`w-82 bg-white bg-opacity-95 rounded-2xl border-solid border-4 border-gray-300 transition-height ease-in-out duration-500`}
    >
      <div className="grid grid-cols-1 divide-y divide-gray-400 space-y-2 mx-4">
        <h3 className="mt-4 text-xl font-aiea font-semibold">
          Search By Global Industry
        </h3>
        <div className="flex space-x-12 justify-items-start w-full overflow-x-auto"></div>
      </div>
      <div className="font-aiea text-right mx-4 my-5 text-denim-900">
        {sectors.isLoading ? (
          <div className="flex justify-center">
            <Spinner className="text-denim-500 h-10 w-10" />
          </div>
        ) : sectors.data ? (
          <div className="flex flex-col">
            {sectors.data.filter(sector => sector === SAMPLE_SECTOR_NAME).map((sector) => {
              return (
                <div key={sector}>
                  <span
                    onClick={() => {
                      onSampleSectorSelected(sector);
                    }}
                    className={`
                            font-bold transitional-all ease-out duration-450
                            ${isCurrentSector(sector)
                        ? "text-lg mt-5 bg-turqoise-blue bg-opacity-70 hover:text-white"
                        : `${getExpertsCount(sector) > 0 ? "hover:text-turqoise-blue bg-opacity-0 hover:cursor-pointer" : "hover:cursor-pointer text-gray-400 bg-opacity-0"}`
                      }
                      `}
                  >
                    {sector}
                  </span>
                </div>
              );
            })}
            {sectors.data.sort().filter(sector => sector !== SAMPLE_SECTOR_NAME).map((sector) => {
              return (
                <div key={sector}>
                  <span
                    onClick={() => {
                      setCurrentSector(undefined);
                      onRestrictedSectorSelected(undefined);
                    }}
                    className={"font-bold transitional-all ease-out duration-450 hover:cursor-pointer text-gray-400 bg-opacity-0"}
                  >
                    {sector}
                  </span>
                </div>
              );
            })}

          </div>
        ) : null}
      </div>
    </div>
  );
};
//#endregion
