import { safe_env_get } from "@/lib/util/env";

export const isProd = process.env["NODE_ENV"] == "production";

export const MONGO_CONNECTION_STRING = safe_env_get(
  "MONGO_CONNECTION_STRING",
  "mongodb://localhost:27017/aiea-platform"
);

export const SENDGRID_API_KEY = safe_env_get("SENDGRID_API_KEY", "");

export const EMAIL_FROM = safe_env_get(
  "EMAIL_FROM",
  "aiea-platform@up2tom.com"
);

export const DOMAIN = safe_env_get("DOMAIN", "http://localhost:4000");

export const UP2TOM_STORAGE_BUCKET_URL = safe_env_get(
  "UP2TOM_STORAGE_BUCKET_URL",
  // "https://storage.googleapis.com/up2tom-assets"
  "https://cdn.up2tom.com"
);

export const STATIC_MODELS = safe_env_get("STATIC_MODELS", "").split(",");

export const UP2TOM_API_HOST = safe_env_get(
  "UP2TOM_API_HOST",
  "https://api.up2tom.com"
);

export const AIEA_SUPPORT_MAILBOX = safe_env_get(
  "AIEA_SUPPORT_MAILBOX",
  "dev.support@aiemploymentagency.com"
);

export const AIEA_PLATFORM_DOMAIN = safe_env_get(
  "AIEA_PLATFORM_DOMAIN",
  "https://aiemploymentagency.com"
);
