import { GuestExpert } from "@/lib/interfaces/GuestExpert";
import { UserIcon } from "@heroicons/react/solid"

//#region INTERFACES
interface UserAvatarProps {
  size: string;
  background: string;
}
//#endregion

export default function UserAvatar({
  size,
  background,
}: UserAvatarProps) {
  return (
    <>
      <span
        className={`inline-flex items-center justify-center ${size} rounded-full ${background} shadow-md`}
      >
        <UserIcon className={`object-cover w-${size} h-${size} text-white mx-2 my-2`}></UserIcon>
      </span>
    </>
  );
}
