import { Dispatch, FunctionComponent } from "react";

//#region INTERFACES
interface MultilineInputFieldProps {
  id: string;
  valueText: string;
  placeholderText: string;
  isTextCentered?: boolean;
  onValueChanged: Dispatch<string>;
}
//#endregion

//#region PUBLIC API
export const MultilineInputField: FunctionComponent<MultilineInputFieldProps> =
  ({ id, valueText, placeholderText, isTextCentered = false, onValueChanged, children }) => {
    return (
      <>
        <textarea
          className={`${isTextCentered ? "text-center" : "text-left"} font-aiea block w-full h-full shadow-sm text-warm-gray-900 focus:ring-denim-900 focus:border-denim-900 border-0 bg-turqoise-blue bg-opacity-40 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 scrollbar-track-rounded scrollbar-thumb-rounded`}
          value={valueText}
          placeholder={placeholderText}
          id={id}
          autoComplete="text"
          style={{ resize: "none" }}
          onChange={(event) => {
            onValueChanged(event.target.value);
          }}
        >
          {children}
        </textarea>
      </>
    );
  };
//#endregion
