import { GetServerSideProps } from "next";
import { useState, useEffect } from "react";
import { isMobile } from 'react-device-detect';
import Head from "next/head";
import Image from "next/image";
import { AIEA_PLATFORM_DOMAIN } from "@/app/config";

import _ from "lodash";
import { useQuery } from "react-query";

import { ExplorationMenu } from "components/elements/ExplorationMenu";
import {
  BriefcaseButton,
  ChatButton,
  EarthButton
} from "components/elements/NavigationButtons";
import { ChatWindow } from "components/elements/ChatWindow";
import { SectorWindow } from "components/elements/SectorWindow";
import { WorldMap } from "@/components/elements/WorldMap";
import { WindowTransition } from "components/transitions/WindowTransition";
// import { AIEBadge } from "@/components/elements/AIEBadge";
import { MITBadge } from "@/components/elements/MITBadge";

import { ExpertsDatasource } from "@/lib/datasource/experts";
import { GuestExpertWithWrappers } from "@/lib/interfaces/GuestExpert";
import { WrapperVisibility, GuestModelWrapper } from "@/lib/interfaces/ModelWrapper";
import { json } from "@/lib/util/json";

import { Modal } from "@/ui/Modal";
import { Spinner } from "@/ui/Spinner";
import { ArrowCircleRightIcon, ChevronDoubleRightIcon } from "@heroicons/react/solid";
import { Field, Formik, Form } from "formik";
import { useMutation } from "react-query";

import { useAppDispatch, useAppSelector } from "@/app/hooks";
import {
  hideChatWindow,
  toggleChatWindow,
} from "features/chat/chatSlice";
import { Api } from "@/app/api";

//#region NESTED TYPES
export enum ExplorationMenuState {
  LoginView,
  WorldExploration,
  RegionExploration,
  SectorExploration,
  ExpertView,
}
//#endregion

//#region CONSTANTS
// TODO HACK A temporary means for associating a city background with any given sector/sub-sector.
// We should probably cache these relationships on the server so that we don't need to hard code changes in when new sectors are added.
const SECTOR_BACKGROUNDS: { [key: string]: [string, string] } = {
  Insurance: ["city-financial.png", "bg-center"],
  "Financial Services": ["city-financial.png", "bg-center"],
  "Health Care": ["city-healthcare.png", "bg-center"],
  Other: ["city-other.png", "bg-center"],
  City: ["city-default.png", "bg-center"],
};
//#endregion

export const getServerSideProps: GetServerSideProps = (
  async function () {
    if (isMobile) {
      return {
        props: {
          initialExpertsWithWrappers: undefined,
        },
      }
    }
    else {
      const initialExpertsWithWrappers =
        await ExpertsDatasource.getExpertsWrappersGuest();
      return {
        props: {
          initialExpertsWithWrappers: json(initialExpertsWithWrappers),
        },
      }

    }
  }
);

interface ExplorePageProps {
  initialExpertsWithWrappers: GuestExpertWithWrappers[];
}

//#region INTERFACES
interface AccessRequest {
  name: string;
  email: string;
}

interface DemoRequest {
  name: string;
  email: string;
}
//#endregion

//#region PUBLIC API
export default function ExplorePage({
  initialExpertsWithWrappers,
}: ExplorePageProps) {
  const dispatch = useAppDispatch();

  //#region VARIABLES - STATE
  const [selectedRegion, setSelectedRegion] = useState<string>();
  // HACK Track the last selected region to allow auto expansion of collapsed exploration menu.
  const [lastRegion, setLastRegion] = useState<string>();
  const [selectedSector, setSelectedSector] = useState<string>();
  const [selectedExpert, setSelectedExpert] = useState<GuestExpertWithWrappers>();
  const [selectedWrapper, setSelectedWrapper] = useState<GuestModelWrapper>();
  const [isSectorsOpen, setIsSectorsOpen] = useState(true);
  const [menuState, setMenuState] = useState<ExplorationMenuState>(
    ExplorationMenuState.WorldExploration
  );
  const { chatWindowVisible } = useAppSelector((state) => state.chat);
  const expertsWrappersGuest = useQuery(
    "expertsWrappersGuest",
    Api.getExpertsWrappersGuest,
    {
      initialData: initialExpertsWithWrappers,
    }
  );

  const [hasShownWelcome, setHasShownWelcome] = useState(false);

  const [isRequestingAccess, setIsRequestingAccess] = useState(false);
  const [accessDetailsValid, setAccessDetailsValid] = useState(true);
  const requestAccessMutation = useMutation(({ name, email }: AccessRequest) =>
    Api.requestAccess(name, email)
  );

  const [isRequestingDemo, setIsRequestingDemo] = useState(false);
  const [demoDetailsValid, setDemoDetailsValid] = useState(true);
  const requestDemoMutation = useMutation(({ name, email }: DemoRequest) =>
    Api.requestDemo(name, email)
  );

  const [content, setContent] = useState<any>();
  useEffect(() => {
    if (isMobile) {
      setContent(<>
        <>
          <div className="px-6 pb-6 pt-4 w-screen h-screen flex-col space-y-4 divide-y divide-gray-400 items-center font-aiea overflow-y-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 scrollbar-track-rounded scrollbar-thumb-rounded">
            <img
              src="/images/logos/aiea-logo-color.png"
              className="mx-auto h-38"
            />

            <div className="flex-col items-center pt-4 bg-white text-aieadark-400">
              <h1 className="text-center font-bold text-2xl">
                The AI Employment Agency
              </h1>
              <h2 className="text-center font-semibold mt-2 text-md text-aiealight-600">
                Democratizing Access to Human Expertise
              </h2>

              <div className="flex-col w-full">
                <div
                  className="group w-full mt-8 flex border-2 focus:outline-none border-aiealight-600 px-4 py-2 mr-8 items-center justify-between">
                  <p className="text-lg font-semibold">
                    What is a Decisioning Digital Twin?
                  </p>
                </div>
                <div className="mt-2 mx-1 text-denim-800">
                  <p className="mt-4 text-sm">
                    An AI Model (or series of models) that responds to a problem or question exactly as its human counterpart would, only in real-time.
                  </p>
                </div>

                <div
                  className="select-none group w-full mt-8 border-2 focus:outline-none border-aiealight-600 px-4 py-2 mr-8">
                  <div className="flex-col w-full h-full">
                    <div
                      className="flex w-full justify-between items-center">
                      <p className="text-lg font-semibold">
                        {"Why Digital Twins?"}
                      </p>
                    </div>
                  </div>
                </div>
                <p className="mt-4 text-sm">
                  Human experts can physically only work in one place at one time, for approximately 8-12 hours a day.
                  Their Digital Twins have none of these physical capacity constraints. The AIEA enables Digital Twins to work in multiple locations, 24/7, 365 days a year.
                </p>
                <p className="mt-6 font-semibold text-sm">
                  Via AIEA, organizations access and employ (or deploy) this unlimited source of expertise in 3 steps:
                </p>
                <p className="mt-2 text-sm">
                  <span className="font-bold mr-2">&#8226;</span> Search for required expertise (anywhere in the world).
                </p>
                <p className="mt-1 text-sm">
                  <span className="font-bold mr-2">&#8226;</span> Engage the expert (via the platform application form).
                </p>
                <p className="mt-1 text-sm">
                  <span className="font-bold mr-2">&#8226;</span> Download Digital Twins into your environment.
                </p>
              </div>

              <div className="flex-col space-y-2 mt-12 text-center text-xs bg-denim-900 font-semibold text-white rounded-lg p-2">
                <p>
                  AIEA is not available on this device yet!
                </p>
                <p>
                  Give it a try on your desktop computer instead.
                </p>
              </div>

              <a
                className="hover:cursor-pointer"
                href="https://www.merlynn-ai.com/"
                target="blank"
              >
                <img
                  src="/images/logos/powered-by-mit-logo.png"
                  className="mx-auto h-32 mt-4"
                />
              </a>
            </div>
          </div>
        </>
      </>);
    }
    else {
      setContent(
        <>
          <div className="z-0 bg-auto bg-no-repeat bg-center min-h-screen flex font-aiea bg-gradient-to-t to-aieadark from-aiealight-800">
            <div className="z-0">
              <WindowTransition
                isShowing={menuState === ExplorationMenuState.ExpertView}
                isOutDisabled={false}
              >
                <div className="z-0 h-screen w-screen">{cityContent}</div>
              </WindowTransition>
            </div>

            <div className="z-0">
              <WindowTransition
                isShowing={menuState !== ExplorationMenuState.ExpertView}
                isOutDisabled={false}
              >
                <div className="z-0 h-screen w-screen">
                  <WorldMap
                    guestExpertsWrappers={
                      expertsWrappersGuest.data?.filter(
                        (expert) =>
                          expert.wrappers.some(
                            (wrapper) =>
                              wrapper.visibility === WrapperVisibility.Visible
                          )
                      ) || []
                    }
                    selectRegion={SelectRegion}
                    selectedRegion={selectedRegion}
                    selectedSector={selectedSector}
                    menuState={menuState}
                  />
                </div>
              </WindowTransition>

              {/* <AIEBadge isColored={false} /> */}
              <MITBadge />
            </div>
          </div>

          {menuState === ExplorationMenuState.WorldExploration && (
            <>
              <div className="absolute left-8 top-9 origin-top-left transform scale-aiea">
                <Image
                  src="/images/logos/aiea-logo-white.png"
                  width="160px"
                  height="87px"
                />
              </div>
              <div className="absolute left-40 space-x-4 top-10 flex justify-between origin-top-left transform scale-aiea">
                <div className="bg-aiealight-700 w-3 my-2"></div>
                <div className="flex-col space-y-1 py-3 font-aiea text-white text-2xl">
                  <h2 className="font-extralight">Welcome to the</h2>
                  <h1 className="font-semibold">AI Employment Agency</h1>
                </div>
              </div>
            </>
          )}

          <>
            <div className="absolute right-20 space-x-4 top-12 flex justify-between origin-top-right transform scale-aiea">
              <a
                // TODO Use env to differentiate login URLs.
                href={AIEA_PLATFORM_DOMAIN}
                className={`z-50 shadow-lg mb-6 rounded-lg text-center font-aiea h-12 w-56 focus:outline-none hover:cursor-pointer hover:bg-gray-100 hover:text-denim-900 hover:border-turqoise-blue bg-aiealight-900 text-white border-solid border-2 border-aiealight-800 transition ease-out duration-300`}
              >
                <p className="py-2 mx-1.5 text-lg font-semibold">Sign In</p>
              </a>
            </div>
            <div className="absolute right-20 space-x-4 top-24 flex justify-between origin-top-right transform scale-aiea">
              <button
                onClick={() => setIsRequestingDemo(true)}
                className={`z-50 shadow-lg mb-6 rounded-lg text-center font-aiea h-12 w-56 focus:outline-none hover:cursor-pointer hover:bg-gray-100 hover:text-denim-900 hover:border-turqoise-blue bg-aiealight-900 text-white border-solid border-2 border-aiealight-800 transition ease-out duration-300`}
              >
                <p className="py-2 mx-1.5 text-lg font-semibold">Request a Demo</p>
              </button>
            </div>
            <div className="absolute right-20 space-x-4 top-36 flex justify-between origin-top-right transform scale-aiea">
              <button
                onClick={() => setIsRequestingAccess(true)}
                disabled={isRequestingAccess}
                className={`bg-aiealight-700 w-56 h-16 mb-4 py-2 shadow-lg hover:shadow-xl border-2 border-aieadark-600 hover:animate-pulse text-white font-bold text-lg rounded-lg px-6 items-center focus:outline-none ${isRequestingAccess
                  ? ""
                  : "hover:bg-gray-100 hover:text-denim-900"
                  } transition-all ease-out duration-300`}
              >
                <p>Join The Community</p>
              </button>
            </div>
          </>

          <div className="absolute z-10 flex justify-between space-x-4 h-16 right-10 bottom-10 origin-bottom-right transform scale-aiea">
            <div
              onClick={() => {
                if (chatWindowVisible && !isSectorsOpen) {
                  dispatch(hideChatWindow());
                }

                if (menuState === ExplorationMenuState.WorldExploration ||
                  menuState === ExplorationMenuState.SectorExploration) {
                  setIsSectorsOpen(!isSectorsOpen);
                }
              }}
            >
              <BriefcaseButton
                isEnabled={
                  (menuState === ExplorationMenuState.WorldExploration ||
                    menuState === ExplorationMenuState.SectorExploration)
                }
                isSelected={isSectorsOpen}
              />
            </div>
            <div
              className="mr-1"
              onClick={DeselectAll}>
              <EarthButton
                isEnabled={true}
                isSelected={
                  menuState === ExplorationMenuState.WorldExploration &&
                  !isSectorsOpen &&
                  !chatWindowVisible
                }
              />
            </div>

            {/* <div
              onClick={() => {
                if (isSectorsOpen && !chatWindowVisible) {
                  setIsSectorsOpen(false);
                }
                dispatch(toggleChatWindow());
              }}
            >
              <ChatButton
                isEnabled={
                  menuState === ExplorationMenuState.WorldExploration
                }
                isSelected={chatWindowVisible}
              />
            </div> */}
          </div>

          <div className="z-100">
            <ExplorationMenu
              guestExpertsWrappers={expertsWrappersGuest.data || []}
              lastRegion={lastRegion}
              selectedRegion={selectedRegion}
              selectedSector={selectedSector}
              selectedExpert={selectedExpert}
              selectedModel={selectedWrapper}
              menuState={menuState}
              onSectorSelected={SelectSector}
              onRegionSelected={SelectRegion}
              onExpertSelected={HandleExpertSelected}
              onModelSelected={HandleModelSelected}
              onCollapseToggled={() => setLastRegion(undefined)}
              onEmployClicked={() => {
                DeselectAll();
              }}
              onBackNavigation={() => {
                setSelectedExpert(undefined);
                setSelectedWrapper(undefined);
                setSelectedSector(undefined);
              }}
              onCloseClicked={DeselectAll}
              onRequestSelected={() => {
                DeselectAll();
                setIsRequestingAccess(true);
              }}
            />
          </div>

          <div className="z-50 absolute right-12 bottom-32 origin-bottom-right transform scale-aiea">
            <WindowTransition isShowing={isSectorsOpen} isOutDisabled={false}>
              <SectorWindow
                guestExpertWrappers={expertsWrappersGuest.data as GuestExpertWithWrappers[]}
                onSampleSectorSelected={HandleGlobalSectorSelected}
                onRestrictedSectorSelected={() => {
                  HandleGlobalSectorSelected(undefined);
                  setIsRequestingAccess(true);
                }} />
            </WindowTransition>
          </div>

          <div className="z-50 absolute right-12 bottom-32 origin-bottom-right transform scale-aiea">
            <WindowTransition isShowing={chatWindowVisible} isOutDisabled={false}>
              <ChatWindow />
            </WindowTransition>
          </div>
        </>
      );
    }
  }, [initialExpertsWithWrappers, selectedRegion, selectedSector, selectedExpert, selectedWrapper, isSectorsOpen, menuState, chatWindowVisible]);
  //#endregion

  //#region EVENT HANDLERS
  const HandleExpertSelected = (expertWithWrapperGuest: GuestExpertWithWrappers) => {
    setSelectedExpert(expertWithWrapperGuest);
    setSelectedRegion(expertWithWrapperGuest.user.profile.location);
    dispatch(hideChatWindow());
    setIsSectorsOpen(false);
    setMenuState(ExplorationMenuState.ExpertView);
  };

  const HandleModelSelected = (modelWrapper: GuestModelWrapper | undefined) => {
    setSelectedWrapper(modelWrapper);
    setMenuState(ExplorationMenuState.ExpertView);
  };

  const HandleGlobalSectorSelected = (sector: string | undefined) => {
    if (SelectSector(sector)) {
      setIsSectorsOpen(false);
      setMenuState(ExplorationMenuState.SectorExploration);
    }
  };
  //#endregion

  //#region HELPER FUNCTIONS
  const SelectRegion = (region: string | undefined) => {
    setLastRegion(selectedRegion);
    setSelectedRegion(region);
    setSelectedSector(undefined);
    setSelectedExpert(undefined);
    setIsSectorsOpen(false);
    setMenuState(ExplorationMenuState.RegionExploration);
  };

  const SelectSector = (sector: string | undefined) => {
    setSelectedWrapper(undefined);
    setSelectedSector(sector);
    return sector !== undefined;
  };

  const DeselectAll = () => {
    setSelectedSector(undefined);
    setLastRegion(undefined);
    setSelectedRegion(undefined);
    setSelectedExpert(undefined);
    setSelectedWrapper(undefined);
    dispatch(hideChatWindow());
    setIsSectorsOpen(false);
    setMenuState(ExplorationMenuState.WorldExploration);
  };
  //#endregion

  let [sectorBg, sectorBgPosition] =
    SECTOR_BACKGROUNDS[selectedSector as string] || SECTOR_BACKGROUNDS["City"];

  let cityContent = (
    <div
      className={`bg-contain bg-no-repeat ${sectorBgPosition} min-h-screen flex font-aiea`}
      style={{
        backgroundImage: `url("/images/cities/${sectorBg}")`,
      }}
    ></div>
  );

  return (
    <>
      <Head>
        <title>AI Employment Agency</title>
      </Head>

      <div>
        {content}

        <div className="transform scale-aiea">
          <Modal open={!hasShownWelcome}>
            <div className="flex pb-8 flex-col items-center font-aiea border-4 border-gray-200 rounded-md transition-all ease-in-out duration-300">
              <p className="text-center font-semibold text-2xl text-denim-900 py-6 px-8">
                Welcome to AI Employment Agency
              </p>
              <p className="text-center text-lg text-gray-800 pt-6 px-12 w-132">
                Send Your Digital Twin To Work
              </p>
              <p className="text-center text-lg text-gray-800 pt-6 pb-12 px-12 w-132">
                Digital Twins created by real human experts in key industries from around the world.
              </p>
              <button
                onClick={() => setHasShownWelcome(true)}
                className="bg-denim-900 w-56 py-2 text-white font-semibold text-lg rounded px-6 items-center focus:outline-none 
                        hover:bg-aiealight-600 hover:text-denim-900
                          transition-all ease-out duration-300"
              >
                Explore
              </button>
            </div>
          </Modal>

          <Modal open={isRequestingAccess}>
            <div className="flex flex-col items-center max-h-200 font-aiea border-4 border-gray-200 rounded-md transition-all ease-in-out duration-300">
              <p className="text-center font-semibold text-2xl text-denim-900 py-6 px-8">
                Would you like to access AIEA?
              </p>
              <p className="text-center text-lg text-gray-800 pt-6 pb-12 pl-12 pr-12 w-132">
                Please provide your name and email address. You will then be
                contacted for registration.
              </p>
              <div className="flex flex-col">
                {!accessDetailsValid &&
                  <p
                    className={`text-left mx-8 text-xs mb-2 text-red-600 transition-all ease-in-out duration-300`}
                  >
                    A name and valid contact email address are required
                  </p>
                }
                {(requestAccessMutation.isSuccess ||
                  requestAccessMutation.isError) && (
                    <p
                      className={`text-left mx-8 text-xs mb-2 ${requestAccessMutation.isSuccess
                        ? "text-green-600"
                        : "text-red-600"
                        } transition-all ease-in-out duration-300`}
                    >
                      {requestAccessMutation.isSuccess
                        ? "You have successfully requested access to AIEA"
                        : "Something went wrong. Please try again in a few minutes"}
                    </p>
                  )}
                <div className="flex w-101 mb-8 mx-8 space-x-2 items-center">
                  <Formik<AccessRequest>
                    initialValues={{ name: "", email: "" }}
                    onSubmit={({ name, email }, { resetForm }) => {
                      if (name.length > 0 && email.includes('@') && email.includes('.')) {
                        setAccessDetailsValid(true);
                        requestAccessMutation.mutate({ name: name, email: email }, {
                          onSuccess: () => {
                            resetForm();
                          }
                        })
                      }
                      else {
                        setAccessDetailsValid(false);
                      }
                    }}
                  >
                    <Form className="flex flex-1">
                      <div className="flex flex-1 gap-x-3 items-center">
                        <div className="flex-col space-y-2">
                          <Field
                            className="bg-blue-100 w-full focus:bg-blue-200 px-3 py-2 text-md font-semibold text-gray-800 placeholder-gray-500 focus:outline-none"
                            placeholder="Full Name"
                            type="name"
                            name="name"
                          />
                          <Field
                            className="bg-blue-100 w-full focus:bg-blue-200 px-3 py-2 text-md font-semibold text-gray-800 placeholder-gray-500 focus:outline-none"
                            placeholder="Email"
                            type="email"
                            name="email"
                          />
                        </div>
                        {requestAccessMutation.isLoading ? (
                          <Spinner className="h-8 text-turqoise-blue" />
                        ) :  // requestAccessMutation.isSuccess ? (
                          //   <CheckIcon className="h-8 text-turqoise-blue hover:text-turqoise-blue transition-all ease-out duration-300" />
                          (
                            <button type="submit">
                              <ArrowCircleRightIcon className="h-8 text-denim-900 hover:text-turqoise-blue transition-all ease-out duration-300" />
                            </button>
                          )}
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
              <div className="flex items-center mb-6 space-x-0.5 text-gray-700 hover:text-turqoise-blue hover:cursor-pointer transition-all ease-out duration-300 hover:underline">
                <a
                  onClick={() => setIsRequestingAccess(false)}
                  className="text-center text-xs"
                >
                  Close
                </a>
                <ChevronDoubleRightIcon className="h-3 text-opacity-80 bg-opacity-80" />
              </div>
            </div>
          </Modal>

          <Modal open={isRequestingDemo}>
            <div className="flex flex-col items-center max-h-200 font-aiea border-4 border-gray-200 rounded-md transition-all ease-in-out duration-300">
              <p className="text-center font-semibold text-2xl text-denim-900 pt-6 px-8">
                Would you like a demo
              </p>
              <p className="text-center font-semibold text-2xl text-denim-900 pb-6 px-8">
                of the AIEA platform?
              </p>
              <p className="text-center text-lg text-gray-800 pt-6 pb-12 pl-12 pr-12 w-132">
                Please provide your name and email address. You will then be
                contacted to schedule a demo.
              </p>
              <div className="flex flex-col">
                {!accessDetailsValid &&
                  <p
                    className={`text-left mx-8 text-xs mb-2 text-red-600 transition-all ease-in-out duration-300`}
                  >
                    A name and valid contact email address are required
                  </p>
                }
                {(requestDemoMutation.isSuccess ||
                  requestDemoMutation.isError) && (
                    <p
                      className={`text-left mx-8 text-xs mb-2 ${requestDemoMutation.isSuccess
                        ? "text-green-600"
                        : "text-red-600"
                        } transition-all ease-in-out duration-300`}
                    >
                      {requestDemoMutation.isSuccess
                        ? "You have successfully requested a demo of the AIEA platform"
                        : "Something went wrong. Please try again in a few minutes"}
                    </p>
                  )}
                <div className="flex w-101 mb-8 mx-8 space-x-2 items-center">
                  <Formik<DemoRequest>
                    initialValues={{ name: "", email: "" }}
                    onSubmit={({ name, email }, { resetForm }) => {
                      if (name.length > 0 && email.includes('@') && email.includes('.')) {
                        setDemoDetailsValid(true);
                        requestDemoMutation.mutate({ name: name, email: email }, {
                          onSuccess: () => {
                            resetForm();
                          }
                        })
                      }
                      else {
                        setDemoDetailsValid(false);
                      }
                    }}
                  >
                    <Form className="flex flex-1">
                      <div className="flex flex-1 gap-x-3 items-center">
                        <div className="flex-col space-y-2">
                          <Field
                            className="bg-blue-100 w-full focus:bg-blue-200 px-3 py-2 text-md font-semibold text-gray-800 placeholder-gray-500 focus:outline-none"
                            placeholder="Full Name"
                            type="name"
                            name="name"
                          />
                          <Field
                            className="bg-blue-100 w-full focus:bg-blue-200 px-3 py-2 text-md font-semibold text-gray-800 placeholder-gray-500 focus:outline-none"
                            placeholder="Email"
                            type="email"
                            name="email"
                          />
                        </div>
                        {requestAccessMutation.isLoading ? (
                          <Spinner className="h-8 text-turqoise-blue" />
                        ) :  // requestAccessMutation.isSuccess ? (
                          //   <CheckIcon className="h-8 text-turqoise-blue hover:text-turqoise-blue transition-all ease-out duration-300" />
                          (
                            <button type="submit">
                              <ArrowCircleRightIcon className="h-8 text-denim-900 hover:text-turqoise-blue transition-all ease-out duration-300" />
                            </button>
                          )}
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
              <div className="flex items-center mb-6 space-x-0.5 text-gray-700 hover:text-turqoise-blue hover:cursor-pointer transition-all ease-out duration-300 hover:underline">
                <a
                  onClick={() => setIsRequestingDemo(false)}
                  className="text-center text-xs"
                >
                  Close
                </a>
                <ChevronDoubleRightIcon className="h-3 text-opacity-80 bg-opacity-80" />
              </div>
            </div>
          </Modal>
        </div >
      </div>
    </>
  );
}
//#endregion
