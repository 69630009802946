import { Dispatch, FunctionComponent, useState, useEffect } from "react";
import _ from "lodash";

import { XIcon } from "@heroicons/react/outline";
import { ExplorationMenuState } from "pages/index";

import { BurgerMenuButton } from "components/elements/BurgerMenuButton";
import { SectorSelectionBar } from "components/elements/SectorSelectionBar";
import { ExpertsList } from "components/elements/ExpertsList";
import { ExpertProfile } from "components/elements/ExpertProfile";
import {
  ViewModelWindow,
} from "components/elements/ModelInteractionWindows";
import { WindowTransition } from "components/transitions/WindowTransition";
import UserAvatar from "@/components/elements/UserAvatar";
import { UserIcon } from "@heroicons/react/solid"

import {
  GuestModelWrapper,
  WrapperVisibility
} from "@/lib/interfaces/ModelWrapper";
import { GuestExpert, GuestExpertWithWrappers } from "@/lib/interfaces/GuestExpert";

//#region INTERFACES
interface ExplorationMenuProps {
  guestExpertsWrappers: GuestExpertWithWrappers[];
  lastRegion?: string;
  selectedRegion?: string;
  selectedSector?: string;
  selectedExpert?: GuestExpertWithWrappers;
  selectedModel?: GuestModelWrapper;
  menuState: ExplorationMenuState;
  onSectorSelected: Dispatch<string | undefined>;
  onRegionSelected: Dispatch<string | undefined>;
  onExpertSelected: Dispatch<GuestExpertWithWrappers>;
  onModelSelected: Dispatch<GuestModelWrapper | undefined>;
  onCollapseToggled: Dispatch<boolean>;
  onEmployClicked: Dispatch<void>;
  onBackNavigation: Dispatch<void>;
  onCloseClicked: Dispatch<void>;
  onRequestSelected: Dispatch<void>;
}
//#endregion

//#region PUBLIC API
export const ExplorationMenu: FunctionComponent<ExplorationMenuProps> = ({
  guestExpertsWrappers,
  lastRegion,
  selectedRegion,
  selectedSector,
  selectedExpert,
  selectedModel,
  menuState,
  onSectorSelected,
  onRegionSelected,
  onExpertSelected,
  onModelSelected,
  onCollapseToggled,
  onEmployClicked,
  onBackNavigation,
  onCloseClicked,
  onRequestSelected
}) => {
  //#region VARIABLES - STATE
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [focusedModel, setFocusedModel] = useState<GuestModelWrapper>();
  //#endregion

  useEffect(() => {
    if (isCollapsed && selectedRegion !== undefined && lastRegion !== undefined && selectedRegion !== lastRegion) {
      setIsCollapsed(false);
      onCollapseToggled(false);
    }
  })

  let content;

  if (menuState === ExplorationMenuState.RegionExploration && !isCollapsed) {
    const wrappers = guestExpertsWrappers
      .filter(({ user }) => user.profile.location === selectedRegion)
      .reduce<GuestModelWrapper[]>((acc, curr) => {
        return [...acc, ...curr.wrappers.filter((wrapper) => wrapper.visibility === WrapperVisibility.Visible)] as GuestModelWrapper[];
      }, []);
    const sampleContentWrappers = wrappers.filter((wrapper) => wrapper.sector === "Sample Content");
    const modelsInRegionGroupBySector = _.groupBy(
      sampleContentWrappers,
      (wrapper: GuestModelWrapper) => wrapper.sector
    );

    content = (
      <div className="overflow-hidden">
        {_.sortBy(Object.keys(modelsInRegionGroupBySector), (_) => _).map(
          (sector: string) => {
            const modelWrappers: GuestModelWrapper[] =
              modelsInRegionGroupBySector[sector];
            const expertsInSector = _.uniq<GuestExpert>(
              modelWrappers.map(
                (modelWrapper) =>
                  (
                    guestExpertsWrappers.find(
                      ({ user }) =>
                        user._id === modelWrapper.user._id
                    ) as GuestExpertWithWrappers
                  ).user
              )
            );
            return (
              <SectorSelectionBar
                key={sector}
                sector={sector}
                experts={expertsInSector}
                modelWrappers={modelWrappers}
                onModelSelect={(modelWrapper) => {
                  // TODO not sure if this is right but it seems to work
                  // const expert = expertsInSector.find(
                  //   (_) => _._id === model.user._id
                  // );
                  const expert = guestExpertsWrappers.find((_) =>
                    _.wrappers.find((wrapper) => wrapper === modelWrapper)
                  );
                  setFocusedModel(modelWrapper);
                  onExpertSelected(expert as GuestExpertWithWrappers);
                }}
                selectedSector={selectedSector}
                onSectorSelected={(sector) => {
                  onSectorSelected(sector);
                }}
              />
            );
          }
        )}
        {
          <div className="bg-aiealight-700 py-2 bg-opacity-30 hover:bg-opacity-50 transition-all ease-in-outh">
            <h3
              onClick={() =>
                onRequestSelected()
              }
              className="mt-4 mx-8 font-aiea font-semibold text-dark-background hover:cursor-pointer"
            >
              {`Discover more experts from ${selectedRegion}`}
            </h3>
            <div
              onClick={() =>
                onRequestSelected()
              }
              className="flex mx-8 space-x-4 justify-start overflow-x-auto hover:cursor-pointer items-center flex-nowrap scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 scrollbar-track-rounded scrollbar-thumb-rounded"
            >
              <div
                className="flex flex-shrink-0 mt-3 mb-4 -space-x-4 items-center flex-nowrap"
              >
                <span
                  className={`z-30 inline-flex items-center justify-center h-14 w-14 rounded-full border-4 border-white bg-turqoise-blue`}
                >
                  <UserIcon className={`object-cover h-14 w-14 text-white mx-2 my-2`}></UserIcon>
                </span>
                <span
                  className={`z-20 inline-flex items-center justify-center h-14 w-14 rounded-full border-4 border-white bg-turqoise-blue`}
                >
                  <UserIcon className={`object-cover h-14 w-14 text-white mx-2 my-2`}></UserIcon>
                </span>
                <span
                  className={`z-10 inline-flex items-center justify-center h-14 w-14 rounded-full border-4 border-white bg-turqoise-blue`}
                >
                  <UserIcon className={`object-cover h-14 w-14 text-white mx-2 my-2`}></UserIcon>
                </span>
                <div className="flex-col mt-4 px-6 font-aiea text-2xl flex-nowrap whitespace-nowrap">
                  + {wrappers.length - sampleContentWrappers.length}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  } else if (
    menuState === ExplorationMenuState.SectorExploration &&
    !isCollapsed
  ) {
    let globalSectorExperts: GuestExpertWithWrappers[] = [];

    // This is very verbose and needs to be refactored. Loop through models in all provided experts and if a model category matches with
    // the selected expert, then add it to our focused experts array.
    for (let i = 0; i < guestExpertsWrappers.length; ++i) {
      if (!globalSectorExperts.includes(guestExpertsWrappers[i])) {
        let expert: GuestExpertWithWrappers = guestExpertsWrappers[i];

        for (let j = 0; j < expert.wrappers.length; ++j) {
          if (expert.wrappers[j].visibility === WrapperVisibility.Visible && expert.wrappers[j].sector === selectedSector) {
            globalSectorExperts.push(expert);
            break;
          }
        }
      }
    }

    content = (
      <>
        <ExpertsList
          experts={globalSectorExperts.map((_) => _.user)}
          onExpertSelect={(expert) => {
            onExpertSelected(
              guestExpertsWrappers.find(
                (_) => _.user === expert
              ) as GuestExpertWithWrappers
            );
          }}
        />
      </>
    );
  } else if (!isCollapsed) {
    content = (
      <ExpertProfile
        selectedExpert={selectedExpert}
        focusedModel={focusedModel as GuestModelWrapper}
        selectedRegion={selectedRegion}
        sectorExperts={guestExpertsWrappers.filter(
          (expertWithWrappers) =>
            expertWithWrappers.wrappers.some(
              (_) => _.sector === selectedSector && _.visibility === WrapperVisibility.Visible
            ) && expertWithWrappers.user.profile.location === selectedRegion
        )}
        onModelSelect={onModelSelected}
        onExpertSelect={onExpertSelected}
        onBackClicked={() => {
          onRegionSelected(selectedRegion);
          onBackNavigation();
        }}
        onCloseClicked={() => {
          setIsCollapsed(false);
          onCloseClicked();
        }}
      />
    );
  }
  return (
    <>
      {menuState !== ExplorationMenuState.WorldExploration && (
        <>
          {!isCollapsed &&
            <div
              onClick={() => {
                if (menuState === ExplorationMenuState.ExpertView) {
                  onRegionSelected(selectedRegion);
                }
                else {
                  setIsCollapsed(false);
                  onCloseClicked();
                }
              }}
              className="absolute inset-0" />
          }
          <div
            className={`z-50 flex absolute left-10 top-10 max-h-210 pointer-events-none object-fill`}
          >
            <div className="flex flex-row space-x-4 pointer-events-auto origin-top-left transform scale-aiea-small">
              <div
                className={`${isCollapsed ? "overflow-y-hidden" : "overflow-y-auto"
                  } w-190 bg-white bg-opacity-95 rounded-2xl border-solid border-4 border-gray-300 object-fill scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 scrollbar-track-rounded scrollbar-thumb-rounded`}
              >
                <div className="flex-col py-4 space-y-4 ">
                  <div className="flex relative space-x-6 px-8 pt-4 pb-2 justify-start items-center">
                    <div
                      onClick={() => {
                        setIsCollapsed(!isCollapsed);
                        onCollapseToggled(isCollapsed);
                      }}
                      className="w-18 h-14"
                    >
                      <BurgerMenuButton isMenuOpen={!isCollapsed} />
                    </div>
                    <div className="flex space-x-1.5 tracking-wide text-turqoise-blue text-4xl font-aiea w-full">
                      <h1 className="font-semibold">AI</h1>
                      <h1 className="font-regular">Employment Agency</h1>
                    </div>

                    <div className="absolute right-4 top-0">
                      <button
                        onClick={() => onCloseClicked()}
                        className="focus:outline-none text-gray-400"
                      >
                        <XIcon className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                  {!isCollapsed && (
                    <>
                      <div className="flex-col w-full py-2">
                        <div className="px-8 w-full font-aiea text-2xl text-denim-900">
                          <h2 className="font-regular">
                            Available{" "}
                            {menuState === ExplorationMenuState.SectorExploration
                              ? "Global "
                              : ""}
                          </h2>
                          <h2 className="font-semibold">
                            {menuState === ExplorationMenuState.RegionExploration
                              ? selectedRegion
                              : menuState === ExplorationMenuState.ExpertView
                                ? selectedSector
                                : menuState ==
                                  ExplorationMenuState.SectorExploration
                                  ? selectedSector
                                  : selectedSector}{" "}
                            Expertise
                          </h2>
                          <div className="h-px rounded-full w-full mt-2 bg-turqoise-blue"></div>
                        </div>
                      </div>
                      <div
                        className={`relative ${isCollapsed ? "" : "w-full h-full"
                          } object-fill overflow-x-hidden overflow-y-auto transition-all ease-in-out duration-200 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 scrollbar-track-rounded scrollbar-thumb-rounded`}
                      >
                        {content}
                      </div>
                    </>
                  )}
                </div>
              </div>

              {selectedModel && selectedExpert && selectedExpert.wrappers.find((wrapper) => wrapper._id === selectedModel._id) ? (
                <div className="z-60 w-144">
                  <WindowTransition
                    isShowing={
                      !isCollapsed &&
                      (selectedModel.visibility ===
                        WrapperVisibility.Visible)
                    }
                    isOutDisabled={true}
                  >
                    <ViewModelWindow
                      modelWrapper={selectedModel}
                      onClosed={() => onRegionSelected(selectedRegion)}
                    />
                  </WindowTransition>
                </div>
              ) : null}
            </div>
          </div>
        </>
      )
      }
    </>
  );
};
//#endregion
