import { FunctionComponent } from "react";

//#region INTERFACES
interface BurgerMenuProps {
  isMenuOpen: boolean;
}
//#endregion

//#region PUBLIC API
export const BurgerMenuButton: FunctionComponent<BurgerMenuProps> = ({
  isMenuOpen,
}) => {
  return (
    <div className="flex h-32 w-112">
      <div
        className={`h-14 w-18 text-turqoise-blue border-turqoise-blue hover:text-denim-900 hover:border-denim-900 bg-opacity-0 border-solid border-2 text-center hover:cursor-pointer transition-all ease-out duration-200`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`absolute ${
            isMenuOpen ? "h-16 w-16" : "h-0 w-16"
          } stroke-current transition-all ease-in-out duration-200`}
          fill="none"
          viewBox="0 0 22 29"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M4 6h16"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`absolute ${
            isMenuOpen ? "h-16 w-16" : "h-16 w-16"
          } stroke-current transition-all ease-in-out duration-200`}
          fill="none"
          viewBox="0 0 22 29"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M4 12h16"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`absolute ${
            isMenuOpen ? "h-16 w-16" : "h-0 w-16"
          } stroke-current transition-all ease-in-out duration-200`}
          fill="none"
          viewBox="0 0 22 29"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M4 18h16"
          />
        </svg>
      </div>
    </div>
  );
};
//#endregion
