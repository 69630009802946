import { GuestExpertWithWrappers } from "@/lib/interfaces/GuestExpert";

class _Api {
  constructor() { }
  sendContactEmail(email: string, name: string, message: string) {
    return fetch("/api/contact-aiea", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, name, message }),
    });
  }

  async getSectors(): Promise<string[]> {
    const response = await fetch("/api/sectors");
    return await response.json();
  }

  async getExpertsWrappersGuest(): Promise<GuestExpertWithWrappers[]> {
    const response = await fetch("/api/experts-wrappers-guest");
    return await response.json();
  }

  async requestAccess(name: string, email: string) {
    return fetch("/api/request-access", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email }),
    });
  }

  async requestDemo(name: string, email: string) {
    return fetch("/api/request-demo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email }),
    });
  }
}

export const Api = new _Api();
