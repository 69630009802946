import { FC } from "react";

import { WorldMapButton } from "@/components/elements/WorldMapButton";
import { ExplorationMenuState } from "pages";

import { GuestExpertWithWrappers } from "@/lib/interfaces/GuestExpert";
import { WrapperVisibility } from "@/lib/interfaces/ModelWrapper";

//#region INTERFACES
interface WorldMapProps {
  guestExpertsWrappers: GuestExpertWithWrappers[];
  selectRegion?: (region: string) => void;
  selectedRegion?: string;
  selectedSector?: string;
  menuState: ExplorationMenuState;
}

interface ExpertsPerRegion {
  region: string;
  modelCount: number;
}
//#endregion

// TODO come up with a better way to do this
// HACK Added fake model count properties for demoing.
const REGION_LOCATIONS: { [key: string]: [number, number, number] } = {
  "South America": [30.5, 65, 18],
  America: [23, 33, 136],
  "South Africa": [49.7, 75.5, 62],
  // Added original United Kingdom entry to correctly place older, incorrectly defined user profiles.
  "United Kingdom": [50, 28.5, 47],
  "United Kingdom / Europe": [50, 28.5, 47],
  Australia: [76, 73.5, 23],
};

function getLocationForRegion(region: string) {
  return REGION_LOCATIONS[region] || [50, 50, 0];
}

export const WorldMap: FC<WorldMapProps> = ({
  guestExpertsWrappers,
  selectRegion = () => { },
  selectedRegion,
  selectedSector,
  menuState,
}) => {
  const regions: string[] = guestExpertsWrappers
    .filter(({ user }) => user.profile.location)
    .map(({ user }) => user.profile?.location)
    .filter((region, index, list) => list.indexOf(region) === index)
    .sort((a, b) => (a > b ? 1 : -1));

  const expertsPerRegion: ExpertsPerRegion[] = regions?.map((region) => {
    const es = (guestExpertsWrappers as GuestExpertWithWrappers[]).filter(
      ({ user }) => user.profile.location == region
    );
    return {
      region,
      modelCount: es.reduce((acc, expert) => acc + expert.wrappers.filter((wrapper) => wrapper.visibility === WrapperVisibility.Visible).length, 0),
    };
  });

  const getRegionalSectorExpertCount = (region: string) => {
    const regionalSectorExperts: GuestExpertWithWrappers[] = [];

    for (let i = 0; i < guestExpertsWrappers.length; ++i) {
      if (
        guestExpertsWrappers[i].user.profile.location == region &&
        !regionalSectorExperts.includes(guestExpertsWrappers[i])
      ) {
        let expert: GuestExpertWithWrappers = guestExpertsWrappers[i];

        for (let j = 0; j < expert.wrappers.length; ++j) {
          if (expert.wrappers[j]?.sector === selectedSector) {
            regionalSectorExperts.push(expert);
            break;
          }
        }
      }
    }

    return regionalSectorExperts.length;
  };

  return (
    <>
      <div
        className="bg-auto bg-no-repeat bg-center min-h-screen flex font-aiea"
        style={{ backgroundImage: `url("/images/world-map.svg")` }}
      />
      {menuState !== ExplorationMenuState.LoginView && (
        <>
          {expertsPerRegion.map(({ region, modelCount }, i) => {
            if (menuState === ExplorationMenuState.SectorExploration
              ? getRegionalSectorExpertCount(region)
              : modelCount > 0) {
              const [left, top, fakeModelCount] =
                getLocationForRegion(region);
              return (
                <div
                  key={i}
                  onClick={() => selectRegion(region)}
                  className="z-40 absolute object-scale-down"
                  style={{
                    left: `${left}%`,
                    top: `${top}%`,
                  }}
                >
                  <WorldMapButton
                    active={region === selectedRegion}
                    totalModelCount={
                      // Use fake model count for guest platform
                      // menuState === ExplorationMenuState.SectorExploration
                      //   ? getRegionalSectorExpertCount(region)
                      //   : modelCount
                      menuState === ExplorationMenuState.SectorExploration
                        ? getRegionalSectorExpertCount(region)
                        : modelCount
                    }
                  />
                </div>
              );
            }
          })}
        </>
      )}
    </>
  );
};
