import { FunctionComponent } from "react";

//#region INTERFACES
interface TristateButtonProps {
  state: number;
  stateDisplayNames: string[];
}
//#endregion

//#region PUBLIC API
export const TristateButton: FunctionComponent<TristateButtonProps> = ({
  state,
  stateDisplayNames,
  children,
}) => {
  return (
    <div
      className={`
          z-50 border-2 text-sm border-transparent shadow-lg
          rounded-lg text-center font-aiea h-8 hover:cursor-pointer hover:bg-gray-100 hover:text-denim-900
          hover:border-solid hover:border-2 hover:border-turqoise-blue transition ease-out duration-300
          ${
            state === 0
              ? "bg-gray-400 text-denim-900"
              : state === 1
              ? "bg-denim-900 text-white"
              : "bg-turqoise-blue text-denim-900"
          }
        `}
    >
      <p className="py-1.5 mx-3">{stateDisplayNames[state]}</p>
      {children}
    </div>
  );
};
//#endregion
